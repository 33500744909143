import { Component, OnInit, Input } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";

import { AuthService } from "src/app/shared/auth.service";
import { ScheduleDemoComponent } from "../schedule-demo/schedule-demo.component";
import { environment } from "src/environments/environment";
import { ViewportScroller } from '@angular/common';
import { ActivatedRoute, Router,UrlSegment ,NavigationEnd  } from '@angular/router';
declare var $: any;

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
  providers: [AuthService]
})
export class HeaderComponent implements OnInit {
  logedIn: number;
  userType: number;
  imgUrl: string;
  budgetLink: string;
  dsrLink: string;
  reportLink: string;
  plLink: string;
  Special_user: string;
  UserName: string;
  memberType: number;
  ScaleMenu: string;
  Budget_Exist_Status: number;
  Template_Type: number;
  routerSetLink: string;
  browserName: any;
  displayhdrIcon: any;
  foreup_click:boolean=false;
 activeMenu: any;
  constructor(public auth: AuthService, private activatedRoute: ActivatedRoute, public dialog: MatDialog, private vps: ViewportScroller, private router: Router) {
   
    console.log(window.location.href.split['/']);
    this.router.events.subscribe(
      (event: any) => {
        if (event instanceof NavigationEnd) {
         if(this.router.url == "/foreup")
         {
          this.activeMenu = "foreup";
         } 
          console.log('this.router.url', this.router.url);
        }
      }
    );
   
  }
  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.

  }
  

  ngOnInit() {
  
    // this.displayhdrIcon = 'http://localhost:4200/foreup';
    
      
  //   if (this.activatedRoute.routeConfig.path === 'foreup')
  //   {
  //  console.log("Testing foreup");    }
    this.checkIE();
    this.imgUrl = environment.imgUrl;
    this
    if (navigator.cookieEnabled == true) {
      this.logedIn = Number(localStorage.getItem("logedIn"));
      this.Budget_Exist_Status = Number(localStorage.getItem("Budget_Exist_Status"));
      this.userType = Number(localStorage.getItem("userType"));
      this.UserName = localStorage.getItem("UserName");
      this.memberType = Number(localStorage.getItem("memberType"));

      this.Special_user = localStorage.getItem("Special_user");
      this.budgetLink = localStorage.getItem("budgetLink");
      this.dsrLink = localStorage.getItem("dsrLink");
      this.reportLink = localStorage.getItem("reportLink");
      this.plLink = localStorage.getItem("plLink");
      this.ScaleMenu = "0";
      this.Template_Type = Number(localStorage.getItem("Template_Type"));
      this.Special_user = localStorage.getItem('Special_user');;
      this.Budget_Exist_Status = Number(localStorage.getItem("Budget_Exist_Status"));
      // console.log(this.auth.isLogedIn());
    }


  }

  checkIE() {
    // In Opera 15+, the true version is after "OPR/" 
    if ((navigator.userAgent.indexOf("OPR/")) != -1) {
      this.browserName = "Opera";
    }
    // In older Opera, the true version is after "Opera" or after "Version"
    else if ((navigator.userAgent.indexOf("Opera")) != -1) {
      this.browserName = "Opera";
    }
    // In MSIE, the true version is after "MSIE" in userAgent
    else if ((navigator.userAgent.indexOf("MSIE")) != -1) {
      this.browserName = "Microsoft Internet Explorer (IE)";
    }
    // In Chrome, the true version is after "Chrome" 
    else if ((navigator.userAgent.indexOf("Chrome")) != -1) {
      this.browserName = "Chrome";
    }
    // In Safari, the true version is after "Safari" or after "Version" 
    else if ((navigator.userAgent.indexOf("Safari")) != -1) {
      this.browserName = "Safari";
    }
    // In Firefox, the true version is after "Firefox" 
    else if ((navigator.userAgent.indexOf("Firefox")) != -1) {
      this.browserName = "Firefox";
    } else {
      this.browserName = "Microsoft Internet Explorer (IE)";
    }

  }

  ExternalLogin() {
    window.location.href = "https://hampton.myabsorb.com/account/externallogin";
    // window.location.href ="http://localhost:4201/#/tid-bits?token=0Lp-BH3i1iFzUQOb05RXrAv4cdmvF0tGQvHHeg-vLgG7D-U9dXmd5x0t_7zdSHmvBAkSTIGtCbZ1_P4CYuXk0Q2"
  }

  openScheduleDemo() {
    const dialogRef = this.dialog.open(ScheduleDemoComponent, {
      width: "500px"
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log("The dialog was closed");
    });
  }

  menuclick() {
    this.ScaleMenu = "1";
  }
  menuEnter() {
    this.ScaleMenu = "0";
  }
  menuLeave() {
    this.ScaleMenu = "1";
  }
  dropdownclick() {
    $('.dropdown.open .dropdown-toggle').dropdown('toggle');
  }
  foreupclick()
  {
    // this.foreup_click = true;
    this.activeMenu = true;
  }
  nonforeup()
  {
    console.log(this);
    // this.foreup_click = false;
    this.activeMenu = false;
  }
  openNav() {
    // document.getElementById("mySidepanel").style.width = "100%";
    document.getElementById("mySidepanel").style.right = "0";
  }

  closeNav() {
    // document.getElementById("mySidepanel").style.width = "0";
    document.getElementById("mySidepanel").style.right = "-100%";
  }
  headerid() {

    // this.vps.scrollToAnchor(elmnt);
    //      this.vps.setOffset([500,500]);
    //    console.log(this.vps.setOffset([500,500]));
    //viewportScroller.scrollToPosition([0, 0]);
    let left = localStorage.getItem('left');
    let top = localStorage.getItem('top');
    let lenum = Number(left);
    let topnum = Number(top);


    this.vps.scrollToPosition([lenum, topnum]);

  }
}
