import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { error } from 'util';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { LogOutAlertComponent } from '../components/log-out-alert/log-out-alert.component';


@Injectable({
  providedIn: 'root'
})
export class AuthService {


  constructor(private router: Router,private toastr: ToastrService,private httpClient:HttpClient,public dialog: MatDialog) { }
  // && sessionStorage.getItem('session') =='login'
  isLogedIn() {
    if(navigator.cookieEnabled == true){
    return (localStorage.getItem('userToken') && sessionStorage.getItem('session') =='login')  ? localStorage.getItem('userToken') : null;
  }
}

  
  isBudgetExist(){
    return localStorage.getItem('Budget_Exist_Status') ? localStorage.getItem('Budget_Exist_Status') : null;

  }

  // isAdmin(){
  //   return localStorage.getItem('isAdmin') ? localStorage.getItem('isAdmin') : null;
  // }

  // isAdminLogedIn(){
  //   return localStorage.getItem('AdminlogedIn') ? localStorage.getItem('AdminlogedIn') : null;
  // }
 
  // && sessionStorage.getItem('session') =='login'
  getMemberType() {
    if(navigator.cookieEnabled == true){
    return (localStorage.getItem('memberType') && sessionStorage.getItem('session') =='login') ?  Number(localStorage.getItem('memberType')) : null;
  }
}

  getUserName() {
    if(navigator.cookieEnabled == true){
    return localStorage.getItem('UserName') ? localStorage.getItem('UserName') : null;
  }
}

  getSpecialUser() {
    if(navigator.cookieEnabled == true){
    return localStorage.getItem("Special_user") ? localStorage.getItem("Special_user") : null;
  }
}

  getTemplateType() {
    if(navigator.cookieEnabled == true){
    return localStorage.getItem("Template_Type") ? localStorage.getItem("Template_Type") : null;
  }
}

  getBudgetExistStatus(){
    if(navigator.cookieEnabled == true){
    return localStorage.getItem('Budget_Exist_Status') ? localStorage.getItem('Budget_Exist_Status') : null;
  }
}

  getBudgetLink() {
    if(navigator.cookieEnabled == true){
    return localStorage.getItem("budgetLink") ? localStorage.getItem("budgetLink") : null;
  }
}

  getDsrLink() {
    if(navigator.cookieEnabled == true){
    return localStorage.getItem("dsrLink") ? localStorage.getItem("dsrLink") : null;
  }
}

  getReportLink() {
    if(navigator.cookieEnabled == true){
    return localStorage.getItem("reportLink") ? localStorage.getItem("reportLink") : null;
  }
}

  getPlLink() {
    if(navigator.cookieEnabled == true){
    return localStorage.getItem("plLink") ? localStorage.getItem("plLink") : null;
  }
}



  login(userName, userPwd) {

    if (userName == 'hampton' && userPwd == 'hampton') {
      console.log('hampnto');
      localStorage.setItem('logedIn', '1');
      localStorage.setItem('userType', '1');
      this.toastr.success('Login successfully!', 'Success!');
      this.router.navigate(['/dashboard']);
    } else if (userName == 'manager' && userPwd == 'manager') {
      console.log('hampnto');
      localStorage.setItem('logedIn', '1');
      localStorage.setItem('userType', '2');
      this.toastr.success('Login successfully!', 'Success!');
      this.router.navigate(['/dashboard']);
    } else if (userName == 'user' && userPwd == 'user') {
      console.log('hampnto');
      localStorage.setItem('logedIn', '1');
      localStorage.setItem('userType', '3');
      this.toastr.success('Login successfully!', 'Success!');
      this.router.navigate(['/dashboard']);
    } else {
      console.log('wrong');    
      this.toastr.warning('User Name/Password incorrect!', 'Failed!');
    }
   
  }

  openLoutDialog(){
    const dialogRef = this.dialog.open(LogOutAlertComponent, {
      width: '350px'
    });
  }

  logOut() {
   this.openLoutDialog();
    this.httpClient.get(environment.rootUrl + '/Api/Hampton/lmslogout')
    .subscribe((data:any)=>{
      // console.log('data');  
      // this.dialogRef.close();
      this.dialog.closeAll();
      let oldLmsUrl = localStorage.getItem('lmsIframUrlOld')
      localStorage.clear();  
      sessionStorage.setItem('session','logout');
      localStorage.setItem('User_Logout', 'logout');
      localStorage.setItem('lmsIframUrlOld', oldLmsUrl);
      // this.lmsAuthenticationLogout();
      this.router.navigate(['/login']);
    },
    error =>{      
      console.log('error',error); 
    });
  
  }

  logOutResetPassword() {
    this.httpClient.get(environment.rootUrl + '/Api/Hampton/lmslogout')
    .subscribe((data:any)=>{
      // console.log('data');  
      localStorage.clear();  
      // this.lmsAuthenticationLogout();
      // this.router.navigate(['/login']);
    },
    error =>{      
      console.log('error',error); 
    });
  
  }

  lmsAuthenticationLogout() {

    return this.httpClient.get('https://hamp.talentlms.com/index/logout');
  }

  adminLogOut() {
    localStorage.clear();
    this.router.navigate(['/admin']);
  }



  logPopUp(){
    // console.log(navigator.cookieEnabled);
    if(navigator.cookieEnabled == true){
  
    if(localStorage.getItem('Tab_Close') == 'Yes' && sessionStorage.getItem('session') =='login'){
    return true;
    }else if(localStorage.getItem('Tab_open') == 'Yes' && sessionStorage.getItem('session') =='login'){
      return true;
    }else if(localStorage.getItem('LoginStatus')){
      if(sessionStorage.getItem('session') =='login'){
          // console.log("current page");
      }else{
        return true;
      }
    }
    else{
      return false;
    }
  }
  }
  createLocalStorage_Tab_Close(){ 
      // console.log("log_out Created");
      localStorage.setItem('Tab_Close', 'Yes');
  }
  createLocalStorage_Tab_open(){
    if(localStorage.getItem('userToken')){
    this.httpClient.get(environment.rootUrl + '/Api/Hampton/lmslogout')
    .subscribe((data:any)=>{
      // console.log('data',data);  
      // console.log(" LMS LogOut.....");
      localStorage.setItem('Tab_open', 'Yes');
      this.localStorageclear();
      // this.router.navigate(['/login']);
    },
    error =>{      
      console.log('error',error); 
      localStorage.setItem('Tab_open', 'Yes');
      this.localStorageclear();
    });
  }
  else{
    // console.log("LMS Not LogIn");
  }
  }
  localStorageclear(){
    localStorage.removeItem('subdomainId');
    localStorage.removeItem('userToken');
    localStorage.removeItem('Account_Type');
    localStorage.removeItem('Account_Name');
    localStorage.removeItem('Account_id');
    localStorage.removeItem('Boss_id');
    localStorage.removeItem('Budget_Exist_Status');
    localStorage.removeItem('Company');
    localStorage.removeItem('Course_Zipcode');
    localStorage.removeItem('Restaurant_id');
    localStorage.removeItem('Special_user');
    localStorage.removeItem('Template_Type');
    localStorage.removeItem('UserName');
    localStorage.removeItem('UserType');
    localStorage.removeItem('activeBusiness');
    localStorage.removeItem('blogUserLogin');
    localStorage.removeItem('budgetLink');
    localStorage.removeItem('budget_end_year');
    localStorage.removeItem('budget_start_year');
   
    localStorage.removeItem('budget_type');
    localStorage.removeItem('cityName');
    localStorage.removeItem('courseData');
    localStorage.removeItem('custom_template_year');
    localStorage.removeItem('dsrLink');
    localStorage.removeItem('expires_in');
    localStorage.removeItem('memberType');
    localStorage.removeItem('plLink');
    localStorage.removeItem('reportLink');
    localStorage.removeItem('restData');
    localStorage.removeItem('UseuserTokenrName');
    localStorage.removeItem('AccountType');
    }
   
}
