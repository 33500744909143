import { Component, OnInit, Inject } from '@angular/core';
import { ImportUserDataComponent } from '../import-user-data/import-user-data.component';
import { MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-email-exist-alert',
  templateUrl: './email-exist-alert.component.html',
  styleUrls: ['./email-exist-alert.component.css']
})
export class EmailExistAlertComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ImportUserDataComponent>) { }

  ngOnInit() {
  }

}
