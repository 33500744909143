import { Component, OnInit, Inject } from '@angular/core';
import { TeamMembersComponent } from '../team-members/team-members.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { SettingsService } from 'src/app/shared/settings.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-delete-alert',
  templateUrl: './delete-alert.component.html',
  styleUrls: ['./delete-alert.component.css']
})
export class DeleteAlertComponent implements OnInit {
  Error_status:any = [];
  constructor(private settingService: SettingsService, private toastr:ToastrService, public dialogRef: MatDialogRef<TeamMembersComponent>,
    @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit() {

  }

  onNoClick(): void {
    // console.log(this.data);
    const valueToStore = Object.assign({}, 0, {      
      UserId: this.data.UserId,
      Posid: this.data.Posid,
      status: 3
    });
    // console.log(valueToStore);
    this.dialogRef.close(true);
    this.settingService.ApiUserDelete(valueToStore)
      .subscribe((data: any) => {
        // console.log(data);
        // if (data == 1) {
        //   this.toastr.success('User deleted successfully!');
      
        // } else {
        //   this.toastr.warning('User not deleted!');
        // }
        
        for(let msgData of data){
          // console.log('msgData',msgData);
          // console.log('msgData.Status',msgData.Status);
          this.Error_status.push(msgData.Status);
          // console.log(this.Error_status);
          if(msgData.Status == 1){
            this.toastr.success(msgData.Message);
          }else{
            this.toastr.warning(msgData.Message);
          }
        }
      },
      error =>{
        console.log(error);        
        this.toastr.error('Oops!','Error!');
      })
  }

}
