import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import { WeatherService } from 'src/app/shared/weather.service';

@Component({
  selector: 'app-foreups',
  templateUrl: './foreups.component.html',
  styleUrls: ['./foreups.component.css']
})
export class ForeupsComponent implements OnInit {
  imgUrl: string;
  cols:any = [];
  Opentobuy: any =[];
  stackedData: any;

  stackedOptions: any;

  constructor(private weatherService:WeatherService) {

      this.cols = [
      { Name: 'Rounds', mtd: 4273,mtd_budget:4497,variance:-224, variance_per: 100},    
      { Name: 'Income', mtd: 0,mtd_budget:0,variance:-9041, variance_per: 100 },  
      { Name: 'Labor', mtd: 83032,mtd_budget:77155,variance:5877, variance_per: 100 },  
  ];

  this.Opentobuy = [
         {
            "id":1,
            "catname":"Inside Golf Ops Hourly",
            "mtdbuget":6921.0,
            "mtdactual":0.0,
            "balance":6921.0
         },
         {
            "id":1,
            "catname":"Outside Golf Ops Hourly",
            "mtdbuget":6871.0,
            "mtdactual":0.0,
            "balance":6871.0
         },
         {
            "id":1,
            "catname":"Front",
            "mtdbuget":3256.0,
            "mtdactual":0.0,
            "balance":3256.0
         },
         {
            "id":1,
            "catname":"Kitchen",
            "mtdbuget":6587.0,
            "mtdactual":0.0,
            "balance":6587.0
         },
         {
            "id":1,
            "catname":"Hourly Turf Labor",
            "mtdbuget":12123.0,
            "mtdactual":0.0,
            "balance":12123.0
         },
         {
            "id":1,
            "catname":"Admin Hourly",
            "mtdbuget":0.0,
            "mtdactual":0.0,
            "balance":0.0
         },
         {
            "id":2,
            "catname":"Golf Merchandise",
            "mtdbuget":5658.0,
            "mtdactual":0.0,
            "balance":5658.0
         },
         {
            "id":2,
            "catname":"Food",
            "mtdbuget":9359.0,
            "mtdactual":0.0,
            "balance":9359.0
         },
         {
            "id":2,
            "catname":"Wine",
            "mtdbuget":282.0,
            "mtdactual":0.0,
            "balance":282.0
         },
         {
            "id":2,
            "catname":"Beer",
            "mtdbuget":5720.0,
            "mtdactual":0.0,
            "balance":5720.0
         },
         {
            "id":2,
            "catname":"Liquor",
            "mtdbuget":1634.0,
            "mtdactual":0.0,
            "balance":1634.0
         },
         {
            "id":3,
            "catname":"Dues & Subscriptions",
            "mtdbuget":1000.0,
            "mtdactual":0.0,
            "balance":1000.0
         },
         {
            "id":3,
            "catname":"Employee Training/Education",
            "mtdbuget":0.0,
            "mtdactual":0.0,
            "balance":0.0
         },
         {
            "id":3,
            "catname":"Events",
            "mtdbuget":0.0,
            "mtdactual":0.0,
            "balance":0.0
         },
         {
            "id":3,
            "catname":"Leases – Carts",
            "mtdbuget":0.0,
            "mtdactual":0.0,
            "balance":0.0
         },
         {
            "id":3,
            "catname":"Member Expense",
            "mtdbuget":0.0,
            "mtdactual":0.0,
            "balance":0.0
         },
         
      ]
    

   }

  ngOnInit() {
    localStorage.setItem('foreup-page','foreup');
    this.imgUrl = environment.imgUrl;

    this.stackedData = {
      labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
      datasets: [{
          type: 'bar',
          label: 'Dataset 1',
          backgroundColor: '#42A5F5',
          data: [
              50,
              25,
              12,
              48,
              90,
              76,
              42
          ]
      }, {
          type: 'bar',
          label: 'Dataset 2',
          backgroundColor: '#66BB6A',
          data: [
              21,
              84,
              24,
              75,
              37,
              65,
              34
          ]
      }, {
          type: 'bar',
          label: 'Dataset 3',
          backgroundColor: '#FFA726',
          data: [
              41,
              52,
              24,
              74,
              23,
              21,
              32
          ]
      }]
  };

  this.stackedOptions = {
      tooltips: {
          mode: 'index',
          intersect: false
      },
      responsive: true,
      scales: {
          xAxes: [{
              stacked: true,
          }],
          yAxes: [{
              stacked: true
          }]
      }
  };

 
  }

}
