import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules} from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { AuthGuard } from './auth/auth.guard';
import { ForeupsComponent } from './foreups/foreups.component';



const routes: Routes = [
  { path: '', component: HomeComponent,pathMatch:'full'},
  { path: 'login',  loadChildren:'./components/login/login.module#LoginModule' },
  { path: 'forget-password',  loadChildren:'./components/forget-password/forget-password.module#ForgetPasswordModule' },
  { path: 'reset-password',  loadChildren:'./components/reset-password/reset-password.module#ResetPasswordModule' },
  { path: 'about-us', loadChildren:'./components/about-us/about-us.module#AboutUsModule' },
  { path: 'contact-us', loadChildren:'./components/contact-us/contact-us.module#ContactUsModule' },
  { path: 'dashboard', loadChildren:'./components/dashboard/dashboard.module#DashboardModule' },
  { path: 'buying-power', loadChildren:'./components/buying-power/buying-power.module#BuyingPowerModule', canActivate: [AuthGuard] },
  { path: 'business-journal', loadChildren:'./components/business-journal/business-journal.module#BusinessJournalModule', canActivate: [AuthGuard] },
  { path: 'tid-bits', loadChildren:'./components/tid-bits/tid-bits.module#TidBitsModule', canActivate: [AuthGuard] },
  { path: 'snapshot', loadChildren:'./components/tid-bits-budget/tid-bits-budget.module#TidBitsBudgetModule', canActivate: [AuthGuard] },
  { path: 'profile', loadChildren:'./components/profile/profile.module#ProfileModule', canActivate: [AuthGuard] },
  { path: 'import-user-data', loadChildren:'./components/import-user-data/import-user-data.module#ImportUserDataModule', canActivate: [AuthGuard] },
  { path: 'team-members', loadChildren:'./components/team-members/team-members.module#TeamMembersModule', canActivate: [AuthGuard] },
  { path: 'services/budget', redirectTo: 'business-journal/daily-sales', pathMatch: 'full' },
  { path: 'services/daily-sales', redirectTo: 'business-journal/daily-sales', pathMatch: 'full' },
  { path: 'custom-template', loadChildren:'./components/custom-template/custom-template.module#CustomTemplateModule', canActivate: [AuthGuard] },
  { path: 'manage-partner',loadChildren:'./components/manage-partner/manage-partner.module#ManagePartnerModule', canActivate: [AuthGuard] },
  { path: 'foreup', component: ForeupsComponent, pathMatch:'full'},
  { path: '**',  loadChildren:'./components/error/error.module#ErrorModule' },
];



@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false,preloadingStrategy: PreloadAllModules})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
