import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { MatDialogRef } from '@angular/material';
import { CommonService } from 'src/app/shared/common.service';

@Component({
  selector: 'app-schedule-demo',
  templateUrl: './schedule-demo.component.html',
  styleUrls: ['./schedule-demo.component.css']
})
export class ScheduleDemoComponent implements OnInit {

  scheduleForm: FormGroup

  constructor(private _fb: FormBuilder, private toastr: ToastrService,public dialogRef: MatDialogRef<ScheduleDemoComponent>, private commonService:CommonService) {
    const emailPatern = '^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$';
    this.scheduleForm = this._fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern(emailPatern)]],
      phone: ['', Validators.required],
      course: ['', Validators.required],
      zip: ['', Validators.required]
    })
  }
  ngOnInit() {
  }
  @ViewChild('form') form;
  onSchedule() {
    if (this.scheduleForm.invalid) {
      return;
    }
    var $this = $('#sch_btn');
    (<any>$this).button('loading');
    // console.log(this.scheduleForm.value);
    this.commonService.AddScheduleDemo(this.scheduleForm.value).subscribe((data:any)=>{
      // console.log(data);
      (<any>$this).button('reset');
      if(data == 1){
        this.toastr.success('Sent Successfully!');
        this.form.resetForm();
        this.dialogRef.close('close add user!');
      }
      else if(data == 0){

        this.toastr.warning("Not Send");
      }
    },
    error=>{
      console.log(error);
      (<any>$this).button('reset');
      this.toastr.warning("Not Send");
    }
    );

    // this.toastr.success('Send Messages Successfully!', 'Success!')
    // this.dialogRef.close('close add user!');

  }
  onNoClick(): void {
    this.dialogRef.close();
  }
}
