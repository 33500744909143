import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ViewportScroller } from '@angular/common';
import { Router }   from '@angular/router';
import { ScheduleDemoComponent } from "../schedule-demo/schedule-demo.component";
import { MatDialog } from '@angular/material';
declare var $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  requestForCostShipment:any;
  playing: boolean = false;
  imgUrl:string;
  public innerWidth: any;
  testimonialOption:any;
  clientOption:any;
  previousUrl: string;

  constructor(private vps: ViewportScroller,private router: Router, public dialog: MatDialog) {

    // router.events
    // .filter(event => event instanceof NavigationEnd)
    // .subscribe(e => {
    //   console.log('prev:', this.previousUrl);
    //   this.previousUrl = e.url;
    // });
    // this.innerWidth = window.innerWidth;
    // if(this.innerWidth > 1200){
    //   this.testimonialOption = {items: 4, margin:30, dots: false, navigation: true};
    //   this.clientOption = {items: 5, margin:30,autoscroll:true, dots: false, navigation: false};
    // }else if(this.innerWidth <= 1200 && this.innerWidth >= 992){
    //   this.testimonialOption = {items: 3, margin:30, dots: false, navigation: true};
    //   this.clientOption = {items: 4, margin:30,autoscroll:true, dots: false, navigation: false};
    // }else if(this.innerWidth <= 991 && this.innerWidth >= 768){
    //   this.testimonialOption = {items: 2, margin:30, dots: false, navigation: true};
    //   this.clientOption = {items: 3, margin:30,autoscroll:true, dots: false, navigation: false};
    // }else if(this.innerWidth <= 767){
    //     this.testimonialOption = {items: 2, margin:30, dots: false, navigation: true};
    //     this.clientOption = {items: 2, margin:30,autoscroll:true, dots: false, navigation: false};
    // }

    this.testimonialOption = {margin:30, dots: false, navigation: true,slideBy: 4,loop:true,
      responsive: {
              0: {
                items: 1
              },
              600: {
                items: 1
              },
              1000: {
                items: 4
              }
            }
      };
      this.clientOption = {margin:30,autoscroll:false, dots: false, navigation: false,
        responsive: {
                0: {
                  items: 2
                },
                600: {
                  items: 1
                },
                1000: {
                  items: 3
                }
              }
        };

  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.


    setTimeout(function(){
      if(!localStorage.getItem('top')){
        var x = $("#financial").position();
        let top1=x.top-70;
        let str=top1.toString();
console.log(str);
        localStorage.setItem('left',x.left.toString());
        localStorage.setItem('top',str);
      }
  //this.scr();
}, 3000);
  }
  ngOnInit() {


    this.imgUrl = environment.imgUrl;

    $('.owl-carousel').owlCarousel({
      margin:10,
      items:8
  })

    // $(function () {

    //   $('.owl-test').owlCarousel({
    //     loop: true,
    //     margin: 10,
    //     nav: true,
    //     navText: ['<', '>'],
    //     smartSpeed: 2000,
    //     autoplay: true,
    //     autoplayTimeout: 3000,
    //     autoplayHoverPause: true,

    //     responsive: {
    //       0: {
    //         items: 1
    //       },
    //       600: {
    //         items: 2
    //       },
    //       1000: {
    //         items: 4
    //       }
    //     },

    //   })

    //   $('.owl-team').owlCarousel({
    //     loop: true,
    //     margin: 30,
    //     nav: true,
    //     navText: ['<', '>'],
    //     smartSpeed: 2000,
    //     autoplay: true,
    //     autoplayTimeout: 3000,
    //     autoplayHoverPause: true,

    //     responsive: {
    //       0: {
    //         items: 1
    //       },
    //       600: {
    //         items: 2
    //       },
    //       1000: {
    //         items: 4
    //       }
    //     },

    //   })

    // })

  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
  this.innerWidth = window.innerWidth;
  // console.log('innerWidth',this.innerWidth);
  }

scr(){

  let left=localStorage.getItem('left');
let top=localStorage.getItem('top');
let lenum=Number(left);
let topnum=Number(top);

    let url_ck = this.router.url;
let ur=url_ck.split('#');
if(ur[1]=='financial'){
      this.vps.scrollToPosition([lenum,topnum]);
  }
}

  @ViewChild('videoPlayer') videoplayer: ElementRef;

  toggleVideo(event: any) {
    console.log(this.playing);
    if (this.playing == false) {
      console.log('f');
      this.videoplayer.nativeElement.pause();
      this.playing = true;
    } else {
      console.log('t');
      this.videoplayer.nativeElement.play();
      this.playing = false;
    }
  }

  openScheduleDemo() {
    const dialogRef = this.dialog.open(ScheduleDemoComponent, {
      width: "500px"
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log("The dialog was closed");
    });
  }


}
