import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContactyService {

  constructor(private http: HttpClient) { }
  addContact(data) {
    var body = JSON.stringify(data);
    // console.log('body');
    // console.log(body);
    var headerOptions = new HttpHeaders({ "Content-Type": "application/json" });
    return this.http.post(
      environment.rootUrl + "/Api/Contact/AddContactDetails",
      body,
      { headers: headerOptions }
    );
  }

  addAboutUsContact(data) {
    var body = JSON.stringify(data);
    // console.log('body');
    // console.log(body);
    var headerOptions = new HttpHeaders({ "Content-Type": "application/json" });
    return this.http.post(
      environment.rootUrl + "/Api/Hampton/AddAboutUsContactDetails",
      body,
      { headers: headerOptions }
    );
  }

}
