import { Component, OnInit, Inject } from '@angular/core';
import { BookedRoundsComponent } from '../tid-bits-budget/booked-rounds/booked-rounds.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-budget-alert',
  templateUrl: './budget-alert.component.html',
  styleUrls: ['./budget-alert.component.css']
})
export class BudgetAlertComponent implements OnInit {
  memberType:number;
  constructor(public dialogRef: MatDialogRef<BookedRoundsComponent>,
    @Inject(MAT_DIALOG_DATA) public data) { 
      this.memberType = Number(localStorage.getItem('memberType'));
    }

  ngOnInit() {
  }

}
