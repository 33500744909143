import { Injectable } from "@angular/core";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Http } from "@angular/http";
import { Observable } from "rxjs";
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: "root"
})
export class SettingsService {
  constructor(private http: HttpClient,private httpClient: Http) {}

  updateChangePassword(data) {
    var body = JSON.stringify(data);
    // console.log(body);
    var headerOptions = new HttpHeaders({ "Content-Type": "application/json" });
    //var requestOptions = new RequestOptions({ method: RequestMethod.Post, headers: headerOptions });
    return this.http.post(
      environment.rootUrl + "/Api/Settings/ChangePassword",
      body,
      { headers: headerOptions }
    );
  }

  GetProfile(memberType) {
    // console.log("memberType", memberType);
    // console.log(typeof memberType);
    if (memberType == 2) {
      // console.log("team member");
      return this.http.get(
        environment.rootUrl + "/Api/Hampton/GetTeamMemberProfile"
      );
    } else {
      // console.log("boss/manager");
      return this.http.get(environment.rootUrl + "/Api/Hampton/GetUserProfile");
    }
  }

  getSnapshotChartData(bud_day,course) {
    // console.log("tes");
    return this.http.get(
      environment.rootUrl +
        "/Api/Hampton/getSnapshotChartData/" +
        bud_day + '/' + course
    );
  }

  ApiGetAddUserDetailManager(id) {
    return this.http.get(
      environment.rootUrl + "/Api/Hampton/GetMgrProfile/" + id
    );
  }

  sendLmsToken(token){
    return this.http.get(environment.rootUrl + "/Api/Hampton/keys/" + token);
  }

  geLogedIn(key){
    return this.http.get('https://hampton.myabsorb.com/account/externallogincallback?id=raj@rcktechiees.com&key='+key+'&relaystate=https://hampton.myabsorb.com/#/dashboard');
  }

  // ExternalLogin() {
  //   const headers = new HttpHeaders({'Access-Control-Allow-Origin': '*'});
    // headers.append('Access-Control-Allow-Headers', 'Content-Type');
    // headers.append('Access-Control-Allow-Methods', 'GET');
    // headers.append('Access-Control-Allow-Origin', '*');
    // return this.http.get('https://hampton.myabsorb.com/account/externallogin',);
    // let httpheader = new HttpHeaders({ 'Content-Type': 'application/json' });
  //   return this.http.get('https://hampton.myabsorb.com/account/externallogin');
  // } 

  ExternalLogin(){  

    return this.http.get('https://hampton.myabsorb.com/account/externallogin');
}

  // ExternalLogin() {
  //   // var body = JSON.stringify(data);
  //   // console.log(body);
  //   var headerOptions = new HttpHeaders({ "Content-Type": "application/json" });
  //   // var requestOptions = new RequestOptions({ method: RequestMethod.Post, headers: headerOptions });
  //   return this.http.post(
  //    "https://hampton.myabsorb.com/account/externallogin",
  //   //  data,
  //     { headers: headerOptions }
  //   ); 
  // }

  updateProfile(data) {
    var body = JSON.stringify(data);
    // console.log(body);
    var headerOptions = new HttpHeaders({ "Content-Type": "application/json" });
    //var requestOptions = new RequestOptions({ method: RequestMethod.Post, headers: headerOptions });
    return this.http.post(
      environment.rootUrl + "/Api/Hampton/UpdateUserDetils",
      body,
      { headers: headerOptions }
    );
  }

  updateProfileManager(data) {
    var body = JSON.stringify(data);
    // console.log(body);
    var headerOptions = new HttpHeaders({ "Content-Type": "application/json" });
    //var requestOptions = new RequestOptions({ method: RequestMethod.Post, headers: headerOptions });
    return this.http.post(
      environment.rootUrl + "/Api/Hampton/UpdateMgrDetils",
      body,
      { headers: headerOptions }
    );
  }

  SaveForecast(data) {
    var body = JSON.stringify(data);
    // console.log(body);
    var headerOptions = new HttpHeaders({ "Content-Type": "application/json" });
    //var requestOptions = new RequestOptions({ method: RequestMethod.Post, headers: headerOptions });
    return this.http.post(
      environment.rootUrl + "/Api/Hampton/SaveForecast",
      body,
      { headers: headerOptions }
    );
  }
  SaveMonthForecast(data) {
    var body = JSON.stringify(data);
    // console.log(body);
    var headerOptions = new HttpHeaders({ "Content-Type": "application/json" });
    //var requestOptions = new RequestOptions({ method: RequestMethod.Post, headers: headerOptions });
    return this.http.post(
      environment.rootUrl + "/Api/Hampton/SaveMonthForecast",
      body,
      { headers: headerOptions }
    );
  }

  // getForecastPlan(year) {
  //   var body = JSON.stringify(year);
  //   // console.log(body);
  //   var headerOptions = new HttpHeaders({ "Content-Type": "application/json" });
  //   //var requestOptions = new RequestOptions({ method: RequestMethod.Post, headers: headerOptions });
  //   return this.http.post(
  //     environment.rootUrl + "/Api/Hampton/GetForecastPlan/"+year,
  //     body,
  //     { headers: headerOptions }
  //   );
  // }

  getForecastPlan(year,course) {
    return this.http.get(
      environment.rootUrl +
        "/Api/Hampton/GetForecastPlan/" + year + "/" + course
    );
  }
  getMonthForecastPlan(year,month) {
    return this.http.get(
      environment.rootUrl +
        "/Api/Hampton/GetMonthForecastPlan/"+year+"/"+month
    );
  }

  addBookedRounds(data) {
    var body = JSON.stringify(data);
    // console.log(body);
    var headerOptions = new HttpHeaders({ "Content-Type": "application/json" });
    //var requestOptions = new RequestOptions({ method: RequestMethod.Post, headers: headerOptions });
    return this.http.post(
      environment.rootUrl + "/Api/Hampton/UpdateBookedRounds",
      body,
      { headers: headerOptions }
    );
  }

  getBookedRounds(budget_year, budget_month, budget_week,course) {
    return this.http.get(
      environment.rootUrl +
        "/Api/Hampton/getBookedRounds/" +
        budget_year +
        "/" +
        budget_month +
        "/" +
        budget_week +
         "/" +
         course
    );
  }

  // theoritical food cost starts here

  getMonthlySalesList(sales_month_id, budget_year) {
    return this.http.get(
      environment.rootUrl +
        "/Api/Settings/GetMonthlySales/" +
        sales_month_id +
        "/" +
        budget_year
    );
  }

  getMenuList() {
    return this.http.get(environment.rootUrl + "/Api/Settings/GetFoodMenu");
  }

  getIngredientList(menuId) {
    return this.http.get(
      environment.rootUrl + "/Api/Settings/GetIngresients/" + menuId
    );
  }

  addMenu(data) {
    var body = JSON.stringify({ itemRows: data });
    // console.log(body);
    var headerOptions = new HttpHeaders({ "Content-Type": "application/json" });
    //var requestOptions = new RequestOptions({ method: RequestMethod.Post, headers: headerOptions });
    return this.http.post(environment.rootUrl + "/Api/Settings/AddMenu", body, {
      headers: headerOptions
    });
  }

  addIgredients(data, dish_details) {
    var body = JSON.stringify({
      ingredientRows: data,
      dish_details: dish_details
    });
    // console.log(body);
    var headerOptions = new HttpHeaders({ "Content-Type": "application/json" });
    //var requestOptions = new RequestOptions({ method: RequestMethod.Post, headers: headerOptions });
    return this.http.post(
      environment.rootUrl + "/Api/Settings/UpdateIngredients",
      body,
      { headers: headerOptions }
    );
  }

  updateIgredients(data) {
    var body = JSON.stringify(data);
    // console.log(body);
    var headerOptions = new HttpHeaders({ "Content-Type": "application/json" });
    //var requestOptions = new RequestOptions({ method: RequestMethod.Post, headers: headerOptions });
    return this.http.post(
      environment.rootUrl + "/Api/Settings/UpdateIngredients",
      body,
      { headers: headerOptions }
    );
  }

  deleteMenu(data) {
    var body = JSON.stringify(data);
    // console.log(body);
    var headerOptions = new HttpHeaders({ "Content-Type": "application/json" });
    //var requestOptions = new RequestOptions({ method: RequestMethod.Post, headers: headerOptions });
    return this.http.post(
      environment.rootUrl + "/Api/Settings/deleteMenu",
      body,
      { headers: headerOptions }
    );
  }

  deleteIngredient(data) {
    var body = JSON.stringify(data);
    // console.log(body);
    var headerOptions = new HttpHeaders({ "Content-Type": "application/json" });
    //var requestOptions = new RequestOptions({ method: RequestMethod.Post, headers: headerOptions });
    return this.http.post(
      environment.rootUrl + "/Api/Settings/deleteIngredient",
      body,
      { headers: headerOptions }
    );
  }

  // theoritical food cost ends here

  EditAlertMessageSave(data) {
    var body = JSON.stringify(data);
    // console.log(body);
    var headerOptions = new HttpHeaders({ "Content-Type": "application/json" });
    //var requestOptions = new RequestOptions({ method: RequestMethod.Post, headers: headerOptions });
    return this.http.post(
      environment.rootUrl + "/Api/Settings/SaveDetails",
      body,
      { headers: headerOptions }
    );
  }

  GetEditAlertMessage(Catid, SubCatid, sid = 0) {
    return this.http.get(
      environment.rootUrl +
        "/Api/Settings/EditAlertMessage/" +
        Catid +
        "/" +
        SubCatid +
        "/" +
        sid
    );
  }

  ApiDeleteAlertMessage(data) {
    var body = JSON.stringify(data);
    // console.log("body");
    // console.log(body);
    var headerOptions = new HttpHeaders({ "Content-Type": "application/json" });
    //var requestOptions = new RequestOptions({ method: RequestMethod.Post, headers: headerOptions });
    return this.http.post(
      environment.rootUrl + "/Api/Settings/DeleteAlertMessage",
      body,
      { headers: headerOptions }
    );
  }

  ApiUpdateAlertMessage(data, mainCat, subCat, subname) {
    var json = {
      NotificationDetails: data,
      MainCategory: mainCat,
      SubCategory: subCat,
      SubCatName: subname
    };

    var body = JSON.stringify(json);

    // console.log(body);
    var headerOptions = new HttpHeaders({ "Content-Type": "application/json" });
    return this.http.post(
      environment.rootUrl + "/Api/Settings/SaveAlertMessage",
      body,
      { headers: headerOptions }
    );
  }

  ApiDownloadInvoice(month, year, sort_by) {
    return this.http.get(
      environment.rootUrl +
        "/Api/Settings/DownloadInvoice/" +
        year +
        "/" +
        month +
        "/" +
        sort_by
    );
  }
  ApiNewUserSave(data, id) {
    var body = JSON.stringify(data);
    // console.log("body");
    // console.log(body);
    // console.log(id);
    var headerOptions = new HttpHeaders({ "Content-Type": "application/json" });

    if (id == "" || id == null) {
      return this.http.post(
        environment.rootUrl + "/Api/Hampton/AddNewUser",
        body,
        { headers: headerOptions }
      );
    } else {
      return this.http.post(
        environment.rootUrl + "/Api/Hampton/UpdateUser",
        body,
        { headers: headerOptions }
      );
    }
  }

  ApiAddUserList() {
    return this.http.get(environment.rootUrl + '/Api/Hampton/GetUsersListnew');
  }

  // ApiAddUserList(sort: string, order: string, page: number) {
  //   return this.http.get(
  //     environment.rootUrl +
  //       "/Api/Hampton/GetUsersList?sort=" +
  //       sort +
  //       "&order=" +
  //       order +
  //       "&page=" +
  //       Number(page + 1)
  //   );
  // }

  ApiUserDelete(data) {
    var body = JSON.stringify(data);

    var headerOptions = new HttpHeaders({ "Content-Type": "application/json" });
    //var requestOptions = new RequestOptions({ method: RequestMethod.Post, headers: headerOptions });
    return this.http.post(
      environment.rootUrl + "/Api/Hampton/DeleteUser",
      body,
      { headers: headerOptions }
    );
  }

  ApiUpdateActualCOG(data, total, year, month) {
    var json = {
      ActualCOG: data,
      Total: total,
      Month: month,
      Year: year
    };

    var body = JSON.stringify(json);

    // console.log(body);
    var headerOptions = new HttpHeaders({ "Content-Type": "application/json" });
    //var requestOptions = new RequestOptions({ method: RequestMethod.Post, headers: headerOptions });
    return this.http.post(
      environment.rootUrl + "/Api/Settings/UpdateActualCOG",
      body,
      { headers: headerOptions }
    );
  }

  ApiGetActualCOG(year, month) {
    return this.http.get(
      environment.rootUrl + "/Api/Settings/GetActualCOG/" + year + "/" + month
    );
  }

  updateSalesMonthly(data, mont_id, budget_year) {
    var body = JSON.stringify({
      monthlySalesList: data,
      mont_id: mont_id,
      year_id: budget_year
    });
    // console.log(body);
    var headerOptions = new HttpHeaders({ "Content-Type": "application/json" });
    //var requestOptions = new RequestOptions({ method: RequestMethod.Post, headers: headerOptions });
    return this.http.post(
      environment.rootUrl + "/Api/Settings/updateSalesMonthly",
      body,
      { headers: headerOptions }
    );
  }

  ApiGetAddUserDetail(userid, position_id) {
    return this.http.get(
      environment.rootUrl +
        "/Api/Hampton/GetAddUserDetail/" +
        userid +
        "/" +
        position_id
    );
  }

  custom_template_year: string;
  updateCustomeTemplate(val) {
    this.custom_template_year = localStorage.getItem("custom_template_year");

    var body = JSON.stringify({
      template_type: val,
      year: this.custom_template_year
    });
    // console.log(body);
    var headerOptions = new HttpHeaders({ "Content-Type": "application/json" });
    //var requestOptions = new RequestOptions({ method: RequestMethod.Post, headers: headerOptions });
    return this.http.post(
      environment.rootUrl + "/Api/Settings/AddTemplateType",
      body,
      { headers: headerOptions }
    );
  }

  bulkUploadSpredSheetData(data) {
    var body = JSON.stringify(data);
    // console.log(body);
    var headerOptions = new HttpHeaders({ "Content-Type": "application/json" });
    //var requestOptions = new RequestOptions({ method: RequestMethod.Post, headers: headerOptions });
    return this.http.post(
      environment.rootUrl + "/Api/Hampton/UploadTeam",
      body,
      { headers: headerOptions }
    );
  }

  bulkUploadSpredSheetDataValidationFinal(data) {
    var body = JSON.stringify(data);
    // console.log('bulkUploadSpredSheetDataValidation',body);
    var headerOptions = new HttpHeaders({ "Content-Type": "application/json" });
    //var requestOptions = new RequestOptions({ method: RequestMethod.Post, headers: headerOptions });
    return this.http.post(environment.rootUrl + "/Api/Hampton/UploadValidation",body,{ headers: headerOptions }
    );
  }

  bulkUploadSpredSheetDataValidation(data) {
    var body = JSON.stringify(data);
    // console.log('bulkUploadSpredSheetDataValidation',body);
    var headerOptions = new HttpHeaders({ "Content-Type": "application/json" });
    //var requestOptions = new RequestOptions({ method: RequestMethod.Post, headers: headerOptions });
    // return this.http.post(environment.rootUrl + "/Api/Hampton/UploadValidation",body,{ headers: headerOptions }
    return this.http.post(environment.rootUrl + "/Api/Hampton/griddisplay",body,{ headers: headerOptions }
    );
  }

  public getJSON(): Observable<any> {
    return this.httpClient.get("./assets/config/config.json").pipe(map(x => x.json()));
}


}
