import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { MatDialogRef } from '@angular/material';
import { ContactyService } from 'src/app/shared/contacty.service';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.css']
})

export class ContactFormComponent implements OnInit {

  subdomain: string;
  imgUrl: string;
  subdomainId: string;
  public contactForm: FormGroup;
  activeAndroid: string;
  constructor(private _fb: FormBuilder, private toastr: ToastrService, private contactyService:ContactyService,public dialogRef: MatDialogRef<ContactFormComponent>) {
    this.contactForm = this._fb.group({
      firstName: ['', Validators.required],
      email: ['', Validators.required],
      message: [''],
      phone: ['']
    });
  }

  ngOnInit() {
  }
  @ViewChild('form') form;
  addContactForm() {
    if (this.contactForm.invalid) {
      return;
    }

  
   
    // console.log(this.contactForm.value);
    this.contactyService.addAboutUsContact(this.contactForm.value)
      .subscribe((data: any) => {
        // console.log(data);

     //   $this.button('reset');
        if (data == 1) {
          this.toastr.success('Sent Successfully!');
          this.form.resetForm();
          // this.contactForm.controls['demo'].setValue('1');
          this.dialogRef.close('close add user!');
        } else if (data == 0) {
          this.toastr.warning('Not Sent!');
        }

      },
        error => {
          this.toastr.warning('Not Sent!');
          
       //   $this.button('reset');
        })

   // this.toastr.success('We will contact you soon!', 'Thank you!')
    this.dialogRef.close('close add user!');

  }

}
