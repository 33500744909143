// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  versionCheckUrl:'',
  rootUrl: 'https://dam.rcktechiees.com',
  xlsSheetName: 'Import_User_List_Test.xlsx',
  lmsUrl: 'https://hamp.talentlms.com',
  imgUrl: 'https://d2a7u30t6wbkim.cloudfront.net',
  appId: '45f4dd45e0f724512ba044c5a2caf4bc',
  baseUrl: 'http://api.openweathermap.org/data/2.5/', 
  units:'imperial'
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
