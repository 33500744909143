import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(private httpClient: HttpClient, private http: Http) { }

  getUserClaims() {
    return this.httpClient.get(environment.rootUrl + '/Api/User/GetUserClaims');
  }

  GetIp() {
    var reqHeader = new Headers({ 'No-Auth': 'True' });
    return this.http.get('https://ipinfo.io', { headers: reqHeader });
  }



  getCommonList(restId) {
    // console.log('restId ' + restId)

    if (localStorage.getItem('UserType') == '0') {
      return this.httpClient.get(environment.rootUrl + '/Api/Common/getBossLabel/' + restId + '/' + localStorage.getItem('custom_template_year'));
    } else {
      return this.httpClient.get(environment.rootUrl + '/Api/Common/getLabel');
    }

  }

  getGeoCurrentLocation(latitude, longitude) {
    var reqHeader = new Headers({ 'No-Auth': 'True' });
    // return this.http.get('https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyCdMiz8KTkQQXj4dVu8NiOXKZ3f04wNnvM&latlng=' + latitude + ',' + longitude + '&sensor=true').map(x => x.json());
    // return this.http.get('https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyCdMiz8KTkQQXj4dVu8NiOXKZ3f04wNnvM&latlng=' + latitude + ',' + longitude).map(x => x.json());
    return this.http.get('https://api.openweathermap.org/data/2.5//weather?APPID=b0df2935c952b99e22eef5f32eaa4265&lat=' + latitude + '&lon=' + longitude + '&units=metric');

  }

  getCityName(zipcode){
    var reqHeader = new Headers({ 'No-Auth': 'True' });
    return this.http.get('https://maps.googleapis.com/maps/api/geocode/json?address='+zipcode+'&key=AIzaSyBjhqLY6nGyyahTf_DS2XJQ26TBjMTvUe8').pipe(map(x => x.json()));

  }

  addSubscribe(form) {
    var body = JSON.stringify(form);
    // console.log(body);
    var headerOptions = new Headers({ 'Content-Type': 'application/json' });
    return this.http.post(environment.rootUrl + '/Api/Common/Subscribe', body, { headers: headerOptions });
  }

  getSnapShotDsr(id, year, month, day) {
    if (localStorage.getItem('UserType') == '0') {
      return this.httpClient.get(environment.rootUrl + '/Api/Common/GetBosspandlsnapshot_Daily/' + year + '/' + month + '/' + day + '/' + id);
    } else {
      return this.httpClient.get(environment.rootUrl + '/Api/Common/Getpandlsnapshot_Daily/' + year + '/' + month + '/' + day);
    }
  }

  getSnapShotBudget(id, year) {
    if (localStorage.getItem('UserType') == '0') {
      return this.httpClient.get(environment.rootUrl + '/Api/Common/GetBosspandlsnapshot_Budget/' + id + '/' + year);
    } else {
      return this.httpClient.get(environment.rootUrl + '/Api/Common/Getpandlsnapshot_Budget/' + year);
    }
  }
  AddScheduleDemo(data){
    var body = JSON.stringify(data);
    // console.log("Schedule Demo");
    // console.log(body);
    // var reqHeader = new Headers({ 'No-Auth': 'True' });
    var headerOptions = new Headers({ "Content-Type": "application/json" });
    return this.http.post(environment.rootUrl + "/Api/Contact/AddScheduleDemo",  body, { headers: headerOptions}).pipe(map(x => x.json()));
  }
  



}
