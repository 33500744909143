import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { TeamMembersComponent } from '../team-members/team-members.component';

@Component({
  selector: 'app-add-course-alert',
  templateUrl: './add-course-alert.component.html',
  styleUrls: ['./add-course-alert.component.css']
})
export class AddCourseAlertComponent implements OnInit {

  courseForm:FormGroup;
  constructor(private _fb:FormBuilder, public dialogRef: MatDialogRef<TeamMembersComponent>,
    @Inject(MAT_DIALOG_DATA) public data) {
    this.courseForm = this._fb.group({
      course1:['',Validators.required]
    })
   }

  ngOnInit() {
  }

  addCourese(){
    if(this.courseForm.invalid){
      return;
    }
    // console.log(this.courseForm.value);
    this.dialogRef.close(this.courseForm.value);
  }

}
