import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WeatherService {

  
  private weatherSource = new BehaviorSubject(this.initWeatherApi(localStorage.getItem("Company")));
  weatherData = this.weatherSource.asObservable();

  constructor(private httpClient: HttpClient) { }


  initWeatherApi(course){
    this.httpClient.get(environment.rootUrl + '/Api/Hampton/weather/' + course)
    .subscribe((data:any)=>{
      // console.log('initWeatherApi',data);
      this.weatherSource.next(data);
      return data;
    })
  }

  // getWeatherApi(course) {
  //   return this.httpClient.get(environment.rootUrl + '/Api/Hampton/weather/' + course);
  // }
  changeWeatherApi(course) {
    this.httpClient.get(environment.rootUrl + '/Api/Hampton/weather/' + course) 
    .subscribe((data:any)=>{
      // console.log('changeWeatherApi',data);
      this.weatherSource.next(data);
    })
  }

}
