import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { AuthService } from 'src/app/shared/auth.service';

@Component({
  selector: 'app-log-out-alert',
  templateUrl: './log-out-alert.component.html',
  styleUrls: ['./log-out-alert.component.css']
})
export class LogOutAlertComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
