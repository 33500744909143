import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ImportUserDataComponent } from '../import-user-data/import-user-data.component';

@Component({
  selector: 'app-import-alert',
  templateUrl: './import-alert.component.html',
  styleUrls: ['./import-alert.component.css']
})
export class ImportAlertComponent implements OnInit {

  constructor( public dialogRef: MatDialogRef<ImportUserDataComponent>,
    @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit() {
  }

  onNoClick(): void {
  // console.log('onNoClick');
    this.dialogRef.close(true);  
  }

}
