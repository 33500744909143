import { Component, OnInit, Inject } from '@angular/core';
import { BookedRoundsComponent } from '../tid-bits-budget/booked-rounds/booked-rounds.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { environment } from 'src/environments/environment';

export interface DialogData {
  errormsg: string;
  popupmsg: string;
} 
@Component({
  selector: 'app-booked-alert',
  templateUrl: './booked-alert.component.html',
  styleUrls: ['./booked-alert.component.css']
})
export class BookedAlertComponent implements OnInit {
  ErrorValue: string;
  showValue: string;
  imgUrl: string;
  constructor(public dialogRef: MatDialogRef<BookedRoundsComponent>,
  @Inject(MAT_DIALOG_DATA) public data : DialogData) { 
  this.ErrorValue = data.errormsg;
  this.showValue = data.popupmsg;
    console.log("Booking Issue"+data.errormsg);
  }
  ngOnInit() {
    this.imgUrl = environment.imgUrl;
  }
  showDialog()
  {
    
  }
}
