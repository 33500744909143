import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-de-active-alert',
  templateUrl: './de-active-alert.component.html',
  styleUrls: ['./de-active-alert.component.css']
})
export class DeActiveAlertComponent implements OnInit {

  imgUrl: string;
  subdomainId: string;
  constructor() { }

  ngOnInit() {
    this.imgUrl = environment.imgUrl;
  }
}
