import { Component, HostListener } from '@angular/core';
import { SlimLoadingBarService } from 'ng2-slim-loading-bar';
import { NavigationCancel, Event, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { AuthService } from './shared/auth.service';
import { Title, Meta } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { VersionCheckService } from './shared/version-check.service';

declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
// Browser Window Close Clear local Storage
export class AppComponent {

  title = 'app15.1';


  constructor(private versionCheckService: VersionCheckService, private httpClient: HttpClient, private _loadingBar: SlimLoadingBarService, private _router: Router, public auth: AuthService, private meta: Meta, private titleService: Title) {

    // console.log('AppComponent.ngOnInit() environment.versionCheckUrl=' + environment.versionCheckUrl);
    //   console.log('environment.versionCheckUrl-1',environment.versionCheckUrl);
    //     this.versionCheckService.initVersionCheck(environment.versionCheckUrl);
    //  console.log(navigator.cookieEnabled);
    if (navigator.cookieEnabled == false) {
      //  alert();   
      this._router.navigate(['/login']);
    } else {
      let tk = localStorage.getItem('userToken');
      if (!(tk)) {
        sessionStorage.setItem('session', 'created');
      }
      this.windowCloseLogout();

      this._router.events.subscribe((event: Event) => {
        this.navigationInterceptor(event);
      });
      localStorage.setItem('subdomainId', '2');

    }


    //  document.cookie = "ThirdPartyCookie=yes;";
    //   if (document.cookie.indexOf("ThirdPartyCookie=") > -1) {
    //   alert("3rd party cookies enabled");
    //   } else {
    //   alert("not 3rd party cookies enabled");
    //   }


    this.meta.addTags([
      { name: 'description', content: 'Hampton Golf, as a premier golf course management company, currently manages over $70 million in top line revenue, with more than 2,000 Team Members nationwide. As a top 25 management company in the world, Hampton Golf is constantly investing into technologies to better our operations and grow our Team Member’s skill sets. Hampton Connect is making business simple. We have made golf course management accessible and affordable so anyone can maximize the profitability of their business. Through our company’s use of the technology, we have created the right solution and are ready to share it with the industry, thus, Hampton Connect.' },
      { name: 'author', content: 'Hampton Golf' },
      { name: 'keywords', content: 'Hampton Golf, Golf course management company,Hampton Connect - Full Business Support' },
      { property: 'og:locale', content: 'en_US' },
      { property: 'og:type', content: 'website' },
      { property: 'og:title', content: 'Hampton Golf' },
      { property: 'og:description', content: 'Hampton Golf, as a premier golf course management company, currently manages over $70 million in top line revenue, with more than 2,000 Team Members nationwide.' },
      { property: 'og:url', content: 'http://hampton.fobesoft.com' },
      { property: 'og:image', content: 'Hampton Golf' },
      { property: 'og:site_name', content: 'Hampton Golf' },
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:site', content: '@hamptongolf' },
      { name: 'twitter:title', content: 'Hampton Connect - Full Business Support' },
      { name: 'twitter:description', content: 'Hampton Connect - Full Business Support' },
      { name: 'twitter:image', content: 'https://alligator.io/images/front-end-cover.png' },
    ]);

    this.setTitle('Hampton Connect - Full Business Support');
  }
  private navigationInterceptor(event: Event): void {
    if (event instanceof NavigationStart) {
      this._loadingBar.start();
    }
    if (event instanceof NavigationEnd) {
      this._loadingBar.complete();
    }
    if (event instanceof NavigationCancel) {
      this._loadingBar.stop();
    }
    if (event instanceof NavigationError) {
      this._loadingBar.stop();
    }
  }

  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);

  }


  locationReload() {
    if (localStorage.getItem('Tab_Close')) {
      //  console.log('Location Reload');
      localStorage.removeItem('Tab_Close');
      this.httpClient.get(environment.rootUrl + '/Api/Hampton/lmslogout')
        .subscribe((data: any) => {
          // console.log('data');  
          // console.log("log_out Created");
          sessionStorage.setItem('session', 'logout');
          // this._router.navigate(['/login']);
          window.location.href = '/login';
        },
          error => {
            // console.log('error',error); 
            sessionStorage.setItem('session', 'logout');
            // this._router.navigate(['/login']);
            window.location.href = '/login';
          });
    }
    if (localStorage.getItem('Tab_open')) {
      // console.log('Location Reload');
      localStorage.removeItem('Tab_open');
      sessionStorage.setItem('session', 'logout');
      window.location.href = '/login';
    }
  }

  windowCloseLogout() {
    //  console.log(navigator.appVersion);

    // Browser Tab Open Clear local Storage

    if (navigator.appVersion.indexOf("Win") != -1) {
      //  if (window.performance) {
      //   //  console.log("window.performance works fine on this browser");
      //  }
      //  if (performance.navigation.type == 1) {
      //   //  console.log("This page is Refresh");
      // localStorage.setItem('TYPE',(performance.navigation.type).toString());
      //  } else {
      //    sessionStorage.setItem('session','open_new');
      //   //  console.log("This page is not reloaded");
      //   //  this.auth.createLocalStorage();
      //    localStorage.setItem('TYPE',(performance.navigation.type).toString());
      //    this.auth.createLocalStorage_Tab_open();
      //   // this.localStorageclear();
      //  }
    } else if (navigator.appVersion.indexOf("Mac") != -1) {

    } else if (navigator.appVersion.indexOf("X11") != -1) {

    } else if (navigator.appVersion.indexOf("Linux") != -1) {

    }

  }

  localStorageclear() {
    localStorage.removeItem('subdomainId');
    localStorage.removeItem('userToken');
    localStorage.removeItem('Account_Type');
    localStorage.removeItem('Account_Name');
    localStorage.removeItem('Account_id');
    localStorage.removeItem('Boss_id');
    localStorage.removeItem('Budget_Exist_Status');
    localStorage.removeItem('Company');
    localStorage.removeItem('Course_Zipcode');
    localStorage.removeItem('Restaurant_id');
    localStorage.removeItem('Special_user');
    localStorage.removeItem('Template_Type');
    localStorage.removeItem('UserName');
    localStorage.removeItem('UserType');
    localStorage.removeItem('activeBusiness');
    localStorage.removeItem('blogUserLogin');
    localStorage.removeItem('budgetLink');
    localStorage.removeItem('budget_end_year');
    localStorage.removeItem('budget_start_year');

    localStorage.removeItem('budget_type');
    localStorage.removeItem('cityName');
    localStorage.removeItem('courseData');
    localStorage.removeItem('custom_template_year');
    localStorage.removeItem('dsrLink');
    localStorage.removeItem('expires_in');
    localStorage.removeItem('memberType');
    localStorage.removeItem('plLink');
    localStorage.removeItem('reportLink');
    localStorage.removeItem('restData');
    localStorage.removeItem('UseuserTokenrName');
    localStorage.removeItem('AccountType');
  }


}
