import { Component, OnInit, Inject } from '@angular/core';
import { ImportUserDataComponent } from '../import-user-data/import-user-data.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-show-excel-error-alert',
  templateUrl: './show-excel-error-alert.component.html',
  styleUrls: ['./show-excel-error-alert.component.css']
})
export class ShowExcelErrorAlertComponent implements OnInit {

  
  errorMsg:any =[];
  constructor(public dialogRef: MatDialogRef<ImportUserDataComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any) {
      // console.log(data);
      this.errorMsg = data;
   }

  ngOnInit() {
  }



}
