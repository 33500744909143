import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { HttpModule } from "@angular/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { CookieService } from "ngx-cookie-service";

import {SlimLoadingBarModule} from 'ng2-slim-loading-bar';
import { OwlModule } from "ngx-owl-carousel";


import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ToastrModule } from "ngx-toastr";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HomeComponent } from "./components/home/home.component";
import { HeaderComponent } from "./components/header/header.component";
import { FooterComponent } from "./components/footer/footer.component";
import { AuthService } from "./shared/auth.service";
import { DeleteAlertComponent } from "./components/delete-alert/delete-alert.component";
import { AuthInterceptor } from "./auth/auth.interceptor";
import { DeActiveAlertComponent } from "./components/de-active-alert/de-active-alert.component";
import { ScheduleDemoComponent } from "./components/schedule-demo/schedule-demo.component";
import { ContactFormComponent } from "./components/contact-form/contact-form.component";
import { MatToolbarModule, MatInputModule, MatIconModule, MatDialogModule, MatButtonModule, MatMenuModule, MatBadgeModule, MatDialogRef } from "@angular/material";
import { BudgetAlertComponent } from './components/budget-alert/budget-alert.component';
import { ImportAlertComponent } from "./components/import-alert/import-alert.component";
import { JournalBudgetAlertComponent } from './components/journal-budget-alert/journal-budget-alert.component';
import { EmailExistAlertComponent } from "./components/email-exist-alert/email-exist-alert.component";
import { AddCourseAlertComponent } from './components/add-course-alert/add-course-alert.component';
// import { LocationStrategy, PathLocationStrategy } from "@angular/common";
import { ShowExcelErrorAlertComponent } from './components/show-excel-error-alert/show-excel-error-alert.component';
import { LogOutAlertComponent } from './components/log-out-alert/log-out-alert.component';
import { BookedAlertComponent } from './components/booked-alert/booked-alert.component';
import { ForeupsComponent } from './foreups/foreups.component';
import {TableModule} from 'primeng/table';
import {  ChartModule } from 'primeng/chart';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    ScheduleDemoComponent,
    DeActiveAlertComponent,
    DeleteAlertComponent,
    ContactFormComponent,
    ImportAlertComponent,
    BudgetAlertComponent,
    JournalBudgetAlertComponent,
    EmailExistAlertComponent,
    AddCourseAlertComponent,
    ShowExcelErrorAlertComponent,
    LogOutAlertComponent,
    BookedAlertComponent,
    ForeupsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SlimLoadingBarModule.forRoot(),
    MatToolbarModule,
    MatInputModule,
    MatIconModule,
    MatDialogModule,
    MatButtonModule,
    MatMenuModule,
    MatBadgeModule,
    ToastrModule.forRoot(),
    HttpClientModule,
    HttpModule,
    FormsModule,
    ReactiveFormsModule,
    OwlModule,
    TableModule,
    ChartModule,
  ],
  providers: [
    AuthService,
    CookieService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {provide: MatDialogRef}
    // {provide: LocationStrategy, useClass: PathLocationStrategy}
  ],
  entryComponents: [ContactFormComponent,
    ScheduleDemoComponent,DeleteAlertComponent,
    DeActiveAlertComponent,ImportAlertComponent,BudgetAlertComponent,
    JournalBudgetAlertComponent,BookedAlertComponent,
    EmailExistAlertComponent,AddCourseAlertComponent,ShowExcelErrorAlertComponent,LogOutAlertComponent],
  bootstrap: [AppComponent]
})
export class AppModule {}
